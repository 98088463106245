.primary {
  @apply bg-others-black rounded-lg text-white border-others-black border-2 disabled:bg-[#CCCCCC] disabled:border-[#CCCCCC] disabled:text-[#666666] disabled:cursor-not-allowed;
}

.secondary {
  @apply bg-primary-main rounded-lg text-white border-primary-main border-2 disabled:bg-[#CCCCCC] disabled:border-[#CCCCCC] disabled:text-[#666666] disabled:cursor-not-allowed;
}

.previous {
  @apply bg-transparent rounded-lg text-[#666666] text-[18px] font-normal tracking-[0.02em] justify-start disabled:text-[#999999] disabled:cursor-not-allowed;
  padding-left: 0px !important;
}

.large {
  @apply py-[10px] px-[51px] text-body1 lg:text-base xl:text-mediumtext;
}

.medium {
  @apply py-[6px] px-[31px] text-smalltext;
}

.small {
  @apply py-1 px-6 text-body1;
}

.savebtn {
  @apply py-[6px] px-[22px];
}
